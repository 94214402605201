import ApiService from '../../../common/api.service';
import mutations from './mutations';

const state = () => ({
	loading: false,
	error: null,
	patterns: [],
	pattern: null
});

const actions = {
	async getPatterns({ commit }) {
		try {
			commit('patternsBegin');
			ApiService.get('/getPatterns').then((response) => {
				if (response.data.status == 'success') {
					
					commit('patternsSuccess', response.data.patterns);
				} else {
					commit('patternsErr', err);
				}
			});
		} catch (err) {
			commit('patternsErr', err);
		}
	},

	// async getPattern({ commit }, trailId) {
	// 	try {
	// 		commit('trailsBegin');
	// 		if (trailId) {
	// 			ApiService.get('/trails/getTrail/' + trailId).then((response) => {
	// 				if (response.data.status == 'success') {
	// 					commit('trailSuccess', response.data.trail);
	// 				} else {
	// 					commit('trailErr', err);
	// 				}
	// 			});
	// 		} else {
	// 			commit('trailSuccess', {
	// 				id: null,
	// 				name: null,
	// 				status: 'inactive',
	// 				type: null,
	// 				application_pattern_id: 1,
	// 				created_at: null,
	// 				updated_at: null,
	// 				trail_items: []
	// 			});
	// 		}
	// 	} catch (err) {
	// 		commit('trailErr', err);
	// 	}
	// },
	// async createPatter({ commit }, trail) {
	// 	try {
	// 		commit('trailsBegin');
	// 	} catch (err) {
	// 		commit('trailErr', err);
	// 	}
	// }
};

export default {
	namespaced: false,
	state,
	actions,
	mutations
};
