import layout_routes from './layout';

const routes = [
	{
		path: '/app',
		component: (resolve) => require(['../MainApp.vue'], resolve),
		children: layout_routes
	},
	{
		path: '/privacypolicy',
		name: 'public-privacy-policy',
		component: (resolve) => require(['../components/pages/legal/public/privacypolicy'], resolve),
		meta: {
			title: 'Política de Privacidade'
		}
	},

	{
		path: '/termsofuse',
		name: 'public-terms-of-use',
		component: (resolve) => require(['../components/pages/legal/public/termsofuse'], resolve),
		meta: {
			title: 'Termos de Uso'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: (resolve) => require(['../components/pages/landing/Login.vue'], resolve),
		meta: {
			title: 'Login'
		}
	},
	{
		path: '/escolas',
		name: 'escola',
		component: (resolve) => require(['../components/pages/landing/SchoolSelection.vue'], resolve),
		meta: {
			title: 'Escolas'
		}
	},
	{
		path: '/cadastroTelefone',
		name: 'cadastroTelefone',
		component: (resolve) => require(['../components/pages/landing/CadTelefone.vue'], resolve),
		meta: {
			title: 'telefone'
		},
		props: true
	},
	{
		path: '/testefelipe',
		name: 'Testes',
		component: (resolve) => require(['../components/pages/landing/TesteFelipe.vue'], resolve),
		meta: {
			title: 'Teste Felipe'
		}
	},
	{
		path: '/',
		name: 'home',
		component: (resolve) => require(['../components/pages/landing/Home.vue'], resolve),
		meta: {
			title: 'Home'
		}
	},
	{
		path: '/singup',
		name: 'singup',
		component: (resolve) => require(['../components/pages/landing/Signup.vue'], resolve),
		meta: {
			title: 'Registro'
		}
	},
	{
		path: '/password/forget',
		name: 'forgetpassword',
		component: (resolve) => require(['../components/pages/landing/ForgetPassword.vue'], resolve),
		meta: {
			title: 'Forget Password'
		}
	},
	{
		path: '/password/reset/:token',
		name: 'resetpassword',
		component: (resolve) => require(['../components/pages/landing/ResetPassword'], resolve),
		meta: {
			title: 'Reset Password'
		}
	},
	// {
	//   path: "/lockscreen",
	//   component: (resolve) => require(["pages/lockscreen"], resolve),
	//   meta: {
	//     title: "Lockscreen",
	//   },
	// },
	{
		path: '/500',
		name: 'error500',
		component: (resolve) => require(['../components/pages/500'], resolve),
		meta: {
			title: '500'
		}
	},
	{
		path: '*',
		component: (resolve) => require(['../components/pages/404'], resolve),
		meta: {
			title: '404'
		}
	}
];
export default routes;
