import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js';
import store from '../store/store';
import ApiService from '../common/api.service';
import alfredOptions from '../alfred';

Vue.use(VueRouter);

const router = new VueRouter({
	routes,
	linkActiveClass: 'active',
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	store.commit('routeChange', 'start');
	
	// caso segunda header seja desativada esse comando a ativa novamente para funcionar normalmente em outras Telas 
	// documentation https://vuejs.org/guide/typescript/options-api.html#augmenting-custom-options
	store.commit('secondHeader', 'open');

	// scroll to top when changing pages
	if (document.scrollingElement) {
		document.scrollingElement.scrollTop = 0;
	} else if (document.documentElement) {
		document.documentElement.scrollTop = 0;
	}

	// CHECAR AS TELAS QUE O CARA N˜AO PODE ESTAR LOGADO PRA ENTRAR. DAR AVISO

	const publicPages = [
		'home',
		'login',
		// "register",
		'forgetpassword',
		'resetpassword',
		'public-privacy-policy',
		'public-terms-of-use',
		'error500',
		'cadastroTelefone',
		'escola'
	];

	const authRequired = !publicPages.includes(to.name);
	// const loggedIn = localStorage.getItem("token");
	// if (authRequired && !loggedIn) {
	//   return next("/home");
	//   // return next("/login");
	// }

	// Bloco para rechecar se o user tem permissão de acessar a rota pretendida.
	if (authRequired) {
		if (!store.state.auth.logged) {
			window.location.href = '/login';
			
		}
		checkUserRole();
	}
	if (!publicPages.includes(to.name)) {
		$cookies.set('alfred_navigation_page', {
			page: to.path
		});
	}

	async function checkUserRole() {
		// const adminRoutes = ["users", "user"];
		const adminRoutes = ['users', 'analytics'];
		const adminRoles = ['superadmin', 'admin'];
		const adminRequired = adminRoutes.includes(to.name);
		if (store.state.auth.user) {
			var checkuserrole = store.state.auth.user.role;
			if (adminRequired) {
				if (!adminRoles.includes(checkuserrole)) {
					router.push({ name: 'dashboard' });
					Vue.swal({
						title: 'Acesso Negado',
						text: 'Você precisa de permissão para acesar a página pretendida.',
						icon: 'error'
					});
				}
			}
		}
	}

	next();
});

//====change page title after route change
// https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/welcome.html

router.afterEach((to, from) => {
	if (store.state.auth.logged) {
		if (store.state.auth.user) {
			ApiService.post('pusher/navigate', {
				userId: store.state.auth.user.id,
				from: from.path,
				to: to.path
			});

			if (store.state.auth.user.roles.length > 0) {
				var userRole = store.state.auth.user.roles[0].display_name;
			} else {
				var userRole = 'Sem Perfil Selecionado';
			}
			const clickstreamData = {
				httpMethod: 'POST',
				path: '/clickstream',
				isBase64Encoded: false,
				body: {
					userId: store.state.auth.user.id,
					name: store.state.auth.user.name,
					avatar: store.state.auth.user.avatar,
					role: userRole,
					from: from.path,
					to: to.path,
					env: process.env.NODE_ENV,
					type: 'Analytics',
					createdAt: moment().unix()
				}
			};
			// fetch('https://dut12q9e78.execute-api.us-east-1.amazonaws.com/prod/clickstream', {
			fetch('https://e0ovxsvkp8.execute-api.us-east-1.amazonaws.com/prod/clickstream', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(clickstreamData)
			});
		}
	}

	if (to.meta.title) {
		document.title = to.meta.title + ' - ' + store.site_name;
		// set pageTitle to null if it is set manually elsewhere
		store.commit('changePageTitle', to.meta.title);
		store.commit('routeChange', 'end');

		// https://developer.zendesk.com/api-reference/widget/chat-api/#updatepath
		// zE("webWidget", "updatePath");

		if (window.innerWidth < 1200) {
			store.commit('left_menu', 'close');
		} else {
			store.commit('left_menu', 'open');
		}
	}
});

export default router;
