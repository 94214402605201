const TOKEN_KEY = 'token';
const AWS_TOKEN_KEY = 'aws_token';
const USER_KEY = 'user';
const USER_PERMISSIONS_KEY = 'userpermissions';
const TOKENAGENT_KEY = 'tokenagent';
const ESCOLAS = 'escolas'
const ESCOLA_SELECIONADA = 'escola_selecionada'

export const getToken = () => {
	return window.localStorage.getItem(TOKEN_KEY);
};
export const getAWSToken = () => {
	return window.localStorage.getItem(AWS_TOKEN_KEY);
};

export const saveToken = (token) => {
	window.localStorage.setItem(TOKEN_KEY, token);
};
export const saveAWSToken = (token) => {
	window.localStorage.setItem(AWS_TOKEN_KEY, token);
};

export const destroyToken = () => {
	window.localStorage.removeItem(TOKEN_KEY);
};
export const destroyAWSToken = () => {
	window.localStorage.removeItem(AWS_TOKEN_KEY);
};

export const getTokenagent = () => {
	return window.localStorage.getItem(TOKENAGENT_KEY);
};

export const saveTokenagent = (tokenagent) => {
	window.localStorage.setItem(TOKENAGENT_KEY, JSON.stringify(tokenagent));
};

export const destroyTokenagent = () => {
	window.localStorage.removeItem(TOKENAGENT_KEY);
};
export const getUser = () => {
	return window.localStorage.getItem(USER_KEY);
};

export const saveUser = (user) => {
	window.localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const destroyUser = () => {
	window.localStorage.removeItem(USER_KEY);
};
export const getUserPermissions = () => {
	return window.localStorage.getItem(USER_PERMISSIONS_KEY);
};
export const clearLocalStorage = () => {
	window.localStorage.clear();
	// var key;
	// for (var i = 0; i < localStorage.length; i++) {
	//   // Aqui consigo manter alguma chave se quiser
	//   key = localStorage.key(i);
	//   if (key != particularKey) {
	//     localStorage.removeItem(key);
	//   }
	// }
};
export const saveUserPermissions = (userpermissions) => {
	window.localStorage.setItem(USER_PERMISSIONS_KEY, JSON.stringify(userpermissions));
};

export const destroyUserPermissions = () => {
	window.localStorage.removeItem(USER_PERMISSIONS_KEY);
};
export const setEscolasUser = (escolas) => {
	window.localStorage.setItem(ESCOLAS, JSON.stringify(escolas));
};
export const getEscolasUser = () => {
	return window.localStorage.getItem(ESCOLAS);
};

export const setEscolaSelecionada = (escola) => {
	window.localStorage.setItem(ESCOLA_SELECIONADA, JSON.stringify(escola));
}

export const getEscolaSelecionada = () => {
	return window.localStorage.getItem(ESCOLA_SELECIONADA);

}
export const destroyEscolas = () => {
	window.localStorage.removeItem(ESCOLAS);
};
export const destroyEscolaSelecionada = () => {
	window.localStorage.removeItem(ESCOLA_SELECIONADA);
};

export default {
	getToken,
	saveToken,
	destroyToken,
	getAWSToken,
	saveAWSToken,
	destroyAWSToken,
	getTokenagent,
	saveTokenagent,
	destroyTokenagent,
	getUser,
	saveUser,
	destroyUser,
	getUserPermissions,
	saveUserPermissions,
	destroyUserPermissions,
	clearLocalStorage,
	setEscolasUser,
	getEscolasUser,
	setEscolaSelecionada,
	getEscolaSelecionada,
	destroyEscolas,
	destroyEscolaSelecionada

};
