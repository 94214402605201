import JwtService from '../common/jwt.service';
import ApiService from '../common/api.service';
import store from './store';

const types = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT'
};

const state = {
	// logged: !!window.localStorage.getItem("user"),
	logged: !!window.localStorage.getItem('token'),
	user: JSON.parse(window.localStorage.getItem('user')),
	userpermissions: JSON.parse(window.localStorage.getItem('userpermissions'))
	// tokenagent: JSON.parse(window.localStorage.getItem("tokenagent") || null),
};

const mutations = {
	[types.LOGIN](state) {
		state.logged = true;
	},

	[types.LOGOUT](state) {
		state.logged = false;
	}
};

const getters = {
	isLogged: (state) => state.logged,
	userRole: (state) => (state.user ? state.user.role : null)

	// userpermissions: state => state.userpermissions,
	// tokenagent: state => state.tokenagent
};

const actions = {
	login({ commit }, response) {
		let data = response.data;
		JwtService.saveToken(data.access_token);
		state.logged = true;
		// state.tokenagent = data.tokenagent;
	},
	authUser({ commit }, response) {
		let data = response.data;
		JwtService.saveUser(data.user);
		JwtService.saveUserPermissions(data.userpermissions);
		state.user = data.user;
		state.userpermissions = data.userpermissions;
	},

	async logout({ commit }) {
		if (state.user.roles.length > 0) {
			var userRole = state.user.roles[0].display_name;
		} else {
			var userRole = 'Sem Perfil Selecionado';
		}

		const clickstreamData = {
			body: {
				userId: state.user.id,
				name: state.user.name,
				avatar: state.user.avatar,
				role: userRole,
				from: window.location.pathname,
				to: '/logout',
				env: process.env.NODE_ENV,
				type: 'Analytics',
				createdAt: moment().unix()
			}
		};

		await fetch('https://e0ovxsvkp8.execute-api.us-east-1.amazonaws.com/prod/clickstream', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(clickstreamData)
		});

		commit('changeLogoutproccess', true);

		await ApiService.get('logout').then((response) => {
			if (response.data.status == 'success') {
				if ($cookies.isKey('alfred_access_counter')) {
					$cookies.remove('alfred_navigation_page');
				}

				ApiService.destroyHeader();
				state.user = null;
				state.userpermissions = null;
				state.logged = false;
				state.userRole = null;
				JwtService.destroyToken();
				JwtService.destroyUser();
				JwtService.destroyUserPermissions();
				JwtService.destroyEscolas();
				JwtService.destroyEscolaSelecionada();

				window.location.href = '/';
			} else {
			}
		});
	},

	logoutInvalidToken({ commit }) {
		ApiService.destroyHeader();
		state.user = null;
		state.userpermissions = null;
		state.logged = false;
		// state.tokenagent = null;
		state.userRole = null;
		JwtService.destroyToken();
		JwtService.destroyUser();
		// JwtService.destroyTokenagent();
		JwtService.destroyUserPermissions();

	},

	changeAvatar({ commit }, avatar) {
		// console.log(avatar);
		state.user.avatar = avatar;
	}
};

export default {
	state,
	mutations,
	getters,
	actions
};
