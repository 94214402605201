export default {
	trailsBegin(state) {
		state.loading = true;
	},
	trailsSuccess(state, data) {
		state.trails = data;
		state.loading = false;
	},
	trailsErr(state, err) {
		state.trails = [];
		state.loading = false;
		state.error = err;
	},

	trailSuccess(state, data) {
		state.trail = data;
		state.loading = false;
	},
	trailErr(state, err) {
		state.trail = null;
		state.loading = false;
		state.error = err;
	}
};
